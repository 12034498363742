<template>
  <b-card header="Modules">
    <b-form inline>
      <b-input-group class="mb-2">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Type to Search"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''" class="mr-2"
            >Clear</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-button
        :to="programId ? { name: 'AdminModuleAddInProgram', params: { programId: programId } } : { name: 'AdminModuleAdd' }"
        variant="primary"
        class="mb-2 mr-2"
      >Create
      </b-button>
    </b-form>

    <b-table
      hover
      small
      outlined
      stacked="sm"
      :items="modules"
      :busy="loading"
      :fields="fields"
      :filter="filter"
      :current-page="currentPage"
      :per-page="perPage"
    >
      <template v-slot:cell(actions)="row">
        <b-button @click="deleteModuleConfirm(row.item)" class="mr-1">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
        </b-button>
        <b-button
          :to="{ name: 'AdminModuleEdit', params: { id: row.item._id } }"
        >
          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-5">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="modulesTotal"
      :per-page="perPage"
      align="center"
    ></b-pagination>
  </b-card>
</template>

<script>
import API from "@/api";
export default {
  props: ["programId"],
  name: "ModulesCard",
  data() {
    return {
      loading: true,
      filter: "",
      fields: [
        { key: "title", sortable: true },
        { key: "description", sortable: true },
        { key: "published", sortable: true },
        { key: "actions", sortable: false }
      ],
      currentPage: 1,
      perPage: 10,
      modules: [],
    };
  },
  computed: {
    modulesTotal() {
      return this.modules.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteModuleConfirm(module) {
      let message = "Are you sure you want to delete module " + module._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          this.deleteModule(module);
        } else {
          // Delete cancelled.
        }
      });
    },
    async deleteModule(module) {
      this.loading = true;

      let filter = {
        _id: module._id,
      };
      let result = await API.delete("modules", filter);
      console.log(result);

      this.modules = [];
      this.fetchData();
    },
    async fetchData() {
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("modules");
      const query = this.programId ? {programId: this.programId} : {};
      const moduleResults = await mongoCollection.find(query);
      this.modules = moduleResults;
      this.loading = false;
    },
  },
};
</script>