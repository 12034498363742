<template>
  <b-card :title="isEditMode ? 'Edit Program' : 'Add Program'" class="m-2">
    <div>
      <b-card v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-card v-if="saving" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-form @submit="onSubmit" v-if="!loading && !saving">
        <b-card title="Program Details" class="mb-2">
          <b-form-group
            id="input-group-title"
            label="Text:"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              required
              placeholder="Enter title."
              v-model="program.title"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            label="Description:"
            label-for="input-description"
          >
            <b-form-input
              id="input-description"
              placeholder="Enter description."
              v-model="program.description"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-code"
            label="Code:"
            label-for="input-code"
          >
            <b-form-input
              id="input-code"
              placeholder="Enter code."
              v-model="program.code"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-cover-image"
            label="Cover Image:"
            label-for="input-cover-image"
          >
            <img :src="program.cover_image" v-if="program.cover_image" class="w-50 "/>
            <b-button size="sm" class="mt-2 d-block" @click="openUploadFile()">Upload</b-button>
            <span class="ml-2 d-inline-block" v-if="uploading"><b-spinner variant="primary" small></b-spinner></span>
          </b-form-group>
          <input type="file" id="upload-file-input" @change="loadImage($event)" accept=".png, .jpg, .jpeg" style="display: none;"/>

          <b-form-checkbox
            id="checkbox-active"
            v-model="program.universal"
            name="checkbox-active"
            class="mb-2"
          >
            Universal
          </b-form-checkbox>
          <modules-card :programId="program && program._id ? program._id.toString() : undefined"/>
        </b-card>

        <b-button type="submit" variant="primary" class="mr-1" size="sm">
          <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
          {{ isEditMode ? 'Save Changes' : 'Add' }}
        </b-button>

        <b-button @click="onCancel" variant="danger" size="sm">
          <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
          Cancel
        </b-button>
      </b-form>
    </div>
    <b-modal size="xl" title="Cover Image Crop" id="cover-image-crop-modal" @ok="uploadFile">
      <cropper
        ref="cropper"
        :stencil-props="{
          aspectRatio: 16/9
        }"
        :src="image.src"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import utils from '@/utils';
import API from "@/api";
import ModulesCard from '../../components/sections/ModulesCard.vue';

export default {
  components: { ModulesCard, Cropper },
  name: "AdminProgramEdit",
  data: () => ({
    isEditMode: false,
    program: {},
    loading: false,
    uploading: false,
    image: {
      src: null,
      type: null
    },
    saving: false,
  }),
  created() {
    this.isEditMode = !!this.$route.params.id;
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  methods: {
    loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
					this.image = {
						src: blob,
						type: utils.getMimeType(e.target.result, files[0].type),
					};
          this.$bvModal.show('cover-image-crop-modal');
				};
				reader.readAsArrayBuffer(files[0]);
			}
    },
    uploadFile() {
			const { canvas } = this.$refs.cropper.getResult();
      this.uploading = true;
			canvas.toBlob((blob) => {
        var reader = new FileReader();
        reader.onload = async (e) => {
          let bytes = new Uint8Array(e.target.result);
          let len = e.target.result.byteLength;
          let binary = "";
          for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
          }
          const base64String = window.btoa(binary);
          this.program.cover_image = await API.uploadFile(
            base64String,
            this.image.type
          );
          this.uploading = false;
        }
        reader.readAsArrayBuffer(blob); 
			}, this.image.type);
    },
    openUploadFile() {
      document.getElementById('upload-file-input').click();
    },
    onCancel() {
      this.$router.push("/admin/programs");
    },
    async onSubmit(event) {
      event.preventDefault();

      this.saving = true;

      if (!this.program._id) {
        this.program.order = parseInt(this.program.order, 10);
        this.program.lastUpdated = new Date().getTime();
        await API.insert("programs", this.program).catch((error) => {
          console.log(error);
        });
      } else {
        let filter = {
          _id: this.program._id,
        };

        let updateData = { ...this.program };
        delete updateData._id;
        let update = { $set: updateData };
        let result = await API.update("programs", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
        console.log(result);
      }
      await API.refresh();
      this.$router.push("/admin/programs");
    },
    async fetchData() {
      this.loading = true;

      const BSON = require("bson");
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("programs");

      let programId = new BSON.ObjectID(this.$route.params.id);
      let filter = {
        _id: programId,
      };

      const result = await mongoCollection.findOne(filter);

      if (result != null) {
        this.program = result;
      }

      this.loading = false;
    },
  },
};
</script>
